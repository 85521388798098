import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import useHover from '@hzdg/use-hover';
import {BackLink} from '@components/Link';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {Headline} from '@components/typography';
import HeroSectionStandalone from '@components/Hero';
import {AmbientVideo} from '@components/Video';
import {JobPostList} from '@components/JobPost';
import {usePageContext} from '@components/Page';
import {OrganicCluster} from '@components/OrganicImageGrid';
import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import JsonLd from '@components/JsonLd';
import {
  StyledSection,
  LatestSection,
  TestimonialSection,
  IconListSection,
  TwoImagesWithContentSection,
} from '@components/sections';

import {styled, ThemeProvider, Colors} from '@styles';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}
interface Page {
  locationpage: LocationPage;
}
interface LocationPage {
  searchDescription: string;
  seoTitle: string;
  title: string;
  id: string;
  body: Block[];
  hero: Block[];
  icon: object;
  phone: string;
  address1: string;
  address2: string;
}

const LatestStyledSection = styled(LatestSection).withConfig({
  componentId: 'locationDetailLatest'
})`
  background: ${Colors.TintedFogLight};
`;

const OrganicGridSection = styled(ResponsiveContainer).withConfig({
  componentId: 'locationDetailOrganicGridSection'
})`
  padding: 0 2em;
`;

const ButtonContainer = styled.div.withConfig({
  componentId: 'locationDetailButtonContainer'
})`
  margin-bottom: 1em;
`;

const StyledHeadline = styled(Headline).withConfig({
  componentId: 'locationDetailHeadlline'
})`
  font-size: 40px;
  letter-spacing: -0.43px;
  line-height: 43px;

  .wide & {
    font-size: 140px;
    letter-spacing: -2.27px;
    line-height: 140px;
  }
`;

const LocationDetail = ({data}: {data: Data}): JSX.Element => {
  // FIXME: throw an error?
  if (!data.cms || !data.cms.page) return null;
  const {locationpage} = data.cms.page;
  // FIXME: throw an error?
  if (!locationpage) return null;
  const {
    searchDescription,
    seoTitle,
    canonicalUrl,
    title,
    icon,
    id,
    hero,
    body,
    phone,
    address1,
    address2,
  }: LocationPage = locationpage;
  usePageContext({shouldInvertMenuColors: true});
  const {detail, testimonial, iconList, jobList, intro} = getDataFromBlockTypes(
    body,
  );
  const {video} = getDataFromBlockTypes(hero);
  const [isHover, hoverProps] = useHover();

  const [city, state, zip] = address2.split(' ');

  return (
    <Fragment>
      <Meta title={title} seoTitle={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <JsonLd
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Corporation',
          name: '2U, Inc.',
          alternateName: '2U',
          url: 'http://2u.com',
          description: `${searchDescription}`,
          sameAs: [
            'https://www.facebook.com/2u',
            'https://twitter.com/2Uinc',
            'https://www.linkedin.com/company/2u',
            'https://en.wikipedia.org/wiki/2U_(company)',
          ],
          ...(address1 &&
            city &&
            state &&
            zip && {
              location: [
                {
                  '@type': 'Place',
                  address: {
                    '@type': 'PostalAddress',
                    streetAddress: `${address1}`,
                    addressLocality: `${city.replace(',', '')}`,
                    addressRegion: `${state}`,
                    postalCode: `${zip}`,
                  },
                  telephone: `${phone}`,
                },
              ],
            }),
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: `${phone}`,
            contactType: 'customer support',
            areaServed: 'US',
            availableLanguage: ['English'],
          },
        }}
        images={[hero[1].image]}
      />
      <ThemeProvider
        theme={{
          fg: Colors.Charcoal,
          bg: Colors.Transparent,
          minPadding: '2em',
          maxWidth: '100%',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '80%',
              ctaIconFg: Colors.Blue,
              ctaIconBg: Colors.Transparent,
              ctaHoverBg: Colors.Blue,
              ctaHoverFg: Colors.White,
            }}
          >
            <HeroSectionStandalone
              heroSectionType="medium"
              careerDetail
              altHero
            >
              <ButtonContainer>
                <BackLink
                  href="/careers/"
                  isHover={isHover}
                  {...hoverProps}
                  filledOnHover
                  filled
                >
                  Back to Careers
                </BackLink>
              </ButtonContainer>
              <StyledHeadline as="h1">{title}</StyledHeadline>
            </HeroSectionStandalone>
          </ThemeProvider>
          {video && video.video && <AmbientVideo videoSrc={video.video.url} />}
          <TwoImagesWithContentSection
            offset={false}
            contentLeft={false}
            {...intro}
          />
          <OrganicGridSection>
            <OrganicCluster {...detail} />
          </OrganicGridSection>
          <ThemeProvider
            theme={{
              fg: Colors.Charcoal,
              ctaIconFg: Colors.Blue,
              ctaIconBg: Colors.White,
              ctaHoverBg: Colors.Blue,
              ctaHoverFg: Colors.White,
              ctaBorder: Colors.Blue,
              ctaFg: Colors.Blue,
              ctaBg: Colors.White,
            }}
          >
            <StyledSection>
              <JobPostList
                locationPage
                id={id}
                icon={icon && icon.url ? icon.url : ''}
                copy={jobList && jobList.subheader ? jobList.subheader : ''}
              />
            </StyledSection>
          </ThemeProvider>
          <TestimonialSection
            {...testimonial}
            withBg={testimonial.testimonialBlock.bgimage}
          />
          <IconListSection
            teamName={title}
            margin="60px auto 100px"
            copy={iconList && iconList.subheader ? iconList.subheader : ''}
          />
          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
};

export default LocationDetail;

export const query = graphql`
  fragment LocationDetailFragment on CMS_LocationPage {
    locationpage {
      id
      seoTitle
      searchDescription
      canonicalUrl
      title
      abbreviation
      phone
      address1
      address2
      icon {
        url
      }
      hero {
        ...videoCmsFragment
      }
      body {
        ...sectionBodyMediaGrid2Block
        ...skillBlockFragment
        ...testimonialSimpleBlockFragment
        ...headerBlockFragment
      }
    }
  }
  query LocationDetail($id: ID!) {
    cms {
      page(id: $id) {
        ...LocationDetailFragment
      }
    }
  }
`;
